import React, { Component } from 'react';
import './vendor/bootstrap/css/bootstrap.min.css'
import './vendor/animate/animate.css'
import './vendor/css-hamburgers/hamburgers.min.css'
import './vendor/select2/select2.min.css'
import './vendor/css/util.css'
import './vendor/css/main.css'
import dwnewsmall from './vendor/images/dwnewsmall.png'

class footerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <div>
            <div style={{borderRadius: '7px', backgroundColor: '#282828', margin: '9px'}} className="text-center"> 
                    <p style={{fontSize: '19px', color: 'white'}}>If you have any problem in logging please contact <u>info@developerweekend.org</u> with your registered email id and phone number</p>
                    <p style={{fontSize: '19px', color: 'white'}}></p>
                  </div>
            
                    </div>  );
    }
}
 
export default footerComponent;