import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import './design/vendor/bootstrap/css/bootstrap.min.css'
import './design/vendor/animate/animate.css'
import './design/vendor/css-hamburgers/hamburgers.min.css'
import './design/vendor/select2/select2.min.css'
import './design/vendor/css/util.css'
import './design/vendor/css/main.css'
import dwnew from './design/vendor/images/dwnew.png'
import dwnewsmall from './design/vendor/images/dwnewsmall.png'
import GoogleButton from 'react-google-button'
import FooterComponet from '../Home/footerComponent'
const SignInPage = () => (
  <div>
    <div className="limiter">
        <div className="container-login100" style={{backgroundImage: 'linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%)'}}>
          <div className="wrap-login100">
            <div className="login100-pic js-tilt" data-tilt>
              <img src={dwnew} alt="IMG" />
            </div>

            <div className="login100-form validate-form" >
              <img src={dwnewsmall} className="mobile-dw-logo" style={{marginLeft: 'auto', marginRight: 'auto', width: '200PX', paddingBottom: '25px'}} alt="IMG" />
              <span className="login100-form-title">
                Member Login
              </span>
              <div style={{marginTop:20}} className="alert alert-info">Thank you for joining us at Developer Weekend BLR 2019.   Please login with your registered Gmail id below to download your e certificates        </div>
              <SignInGoogle />
             
              </div>
              <div style={{marginTop:20}} className="alert alert-warning">If you have any problem in logging to your account OR If you would like to change your name in certificates OR if your Day 2 Workshop is mentioned wrong, <a href="https://docs.google.com/forms/d/e/1FAIpQLScY43vuGH895FbQdz6HrGmL6SidiSYO5RwhvrZGXtpaM5WLcQ/viewform" target="_blank">Please go to this Google Form and fill your details </a></div>  
              <h6 style={{paddingTop:15,color:'white'}}>For any support, please contact info@developerweekend.org</h6>
          </div>
        
        </div>
     
      </div> 
      <FooterComponet/>

    {/* <SignInForm />
    <SignInGoogle />
    <SignInFacebook />
    <SignInTwitter />
    <PasswordForgetLink />
    <SignUpLink /> */}
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <button disabled={isInvalid} type="submit">
          Sign In
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        this.props.firebase.getRUser((socialAuthUser.user.email).toLowerCase()).get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
            this.setState({ error: "No records Found" });
            alert("Sorry!. Your email id not found in our Database. Make sure you login with your registred Gmail ID. For support please contact info@developerweekend.org with your registered email id ")
          
          } else {
            return this.props.firebase.user(socialAuthUser.user.uid).set(
              {
                "Name": doc.data().Name,
                "Email": doc.data().Email,
                "UID": doc.data().UID,
                "PhoneNumber": doc.data().PhoneNumber,
                "tShirt": doc.data().tShirt,
                "college_company": doc.data().college_company,
                "branch_job": doc.data().branch_job,
                "ticketQty": doc.data().ticketQty,
                "couponCode": doc.data().couponCode,
                "paymentID": doc.data().paymentID,
                "paymentMethod": doc.data().paymentMethod,
                "customerEmail": doc.data().customerEmail,
                "customerPhone": doc.data().customerPhone,
                "paidAmount": doc.data().paidAmount,
                "gatewayFee": doc.data().gatewayFee,
                "receivedAmount": doc.data().receivedAmount,
                "paidAt": doc.data().paidAt,
                "ticketDumpId": doc.data().ticketDumpId,
                 roles: {},
              },
              { merge: true },
            ).then(() => {
              this.setState({ error: null });
              this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
              this.setState({ error });
              alert('something went wrong. contact support')
            });
          }
        })
        .catch(err => {
          alert('something went wrong. contact support')
          console.log('Error getting document', err);
        });
      })
      .then(() => {
        this.setState({ error: null });
       // this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
<>
      <GoogleButton style={{width:'100%'}}
  onClick={this.onSubmit}
/>
    
        {error && <p>{error.message}</p>}
        </>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

export default SignInPage;

export { SignInForm, SignInGoogle };
