import React from 'react';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import HeaderComponent from './headerComponent'
import FooterComponent from './footerComponent'
import './home.css'
import TicketComponent from './ticketComponent'
import SelectedWorkshopComponent from './selectedWorkshopComponent'
import SelectDayTwoWorkshop from './selectDayTwoWorkshop'
import AuthUserContext  from '../Session/context';

const HomePage = (props) => (
  <div>
   
   <HeaderComponent/>
   <AuthUserContext.Consumer>
      {authUser => authUser ? <> <TicketComponent firebase={props.firebase} authUser={authUser}/>  <SelectedWorkshopComponent firebase={props.firebase} authUser={authUser}/>  <div className="row container" style={{padding: 27, color:'white', margin: '0px auto'}}>
    </div>
   <div className="row container" style={{padding: 5, margin: '0px auto'}}>
    </div> </> : <div>Loading</div>  }
     </AuthUserContext.Consumer>
  
    <FooterComponent/>
  </div>
 
);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
