import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
 
<div align="right" style={{color: 'white', padding: '5px'}}>
  <button style={{fontSize:15,color:'blue',background:'yellow',padding:7}} type="button" onClick={firebase.doSignOut}>
    Sign Out
  </button>
  </div>
);

export default withFirebase(SignOutButton);
