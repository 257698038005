import React, { Component } from 'react';
import './vendor/bootstrap/css/bootstrap.min.css'
import './vendor/animate/animate.css'
import './vendor/css-hamburgers/hamburgers.min.css'
import './vendor/select2/select2.min.css'
import './vendor/css/util.css'
import './vendor/css/main.css'
import { whileStatement } from '@babel/types';

class selectedWorkshopComponent extends Component {
  _initFirebase = false;
  constructor(props) {
      super(props);
      this.state = { 
        userProfile:null,
        loading: false,
        error: false,
       }
  }

  firebaseInit = () => {
      
    if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;
        const fs = this.props.firebase;
        const thi = this;
        this.onListenForUserInfo(fs, thi);
    }
};

onListenForUserInfo = (fs, thi) => {
    this.setState({ loading: true });
   
    fs.user(thi.props.authUser.uid).get()
    .then(doc => {
      if (!doc.exists) {
        thi.setState({
          userProfile: null,
          error: true,
      });
      } else {
        this.setState({
          userProfile:doc.data(),
          loading: false
        })
      }
    })
    .catch(err => {
      thi.setState({
        userProfile: null,
        error: true,
    });
    });
  
}

componentDidMount() {
  this.firebaseInit();
}

componentDidUpdate() {
  this.firebaseInit();
}
    render() { 
      console.log(this.state.userProfile)
      if(this.state.userProfile && 'workshopName' in this.state.userProfile){
        return (  <>
          <div className="row" style={{padding:5,margin:5}}><div className="col-md-10 col-xs-12 " style={{padding: '0px', marginBottom: '100px', float: 'none',margin: '0 auto'}}>
          <div className="card" style={{margin: '8px', borderColor: '#282828'}}>
            <div className="card-header" style={{backgroundColor: '#282828', color: 'white', borderColor: '#282828'}}>
              <i className="fas fa-ticket-alt" />  Your Day 2 Selected Workshop
            </div>
            <div className="card-body " style={{margin: '0px'}}>
            <div><h4 style={{paddingBottom:10}}>Title: {this.state.userProfile.workshopName}</h4>
      
            </div> 
          <hr/>
              <div className="row">
                <div className="col"><blockquote className="blockquote mb-0" style={{paddingBottom: '15px'}}>
                    <div>Venue</div>
                   
                  <div><p>The workshop seminar hall you will get to know on Day One of the Event. </p></div>
                  </blockquote>
                  <div>Time</div>
                  <p>September 29th 2019; 09:15AM - 04:30PM</p>
                 
                </div>
               
              </div>
              <div style={{marginTop:20,fontSize:20,marginBottom:20}}><u>Your E-Certificates</u></div>
              <a href={"https://www.developerweekend.org/gencertificate/DAY1/"+this.state.userProfile.UID} target="_blank"><button type="button" class="btn btn-primary" style={{marginRight:20,marginBottom:20}}>Click for Day 1 Certificate</button></a>
             <a href={"https://www.developerweekend.org/gencertificate/DAY2/"+this.state.userProfile.UID} target="_blank"> <button type="button" class="btn btn-primary" style={{marginRight:20,marginBottom:20}}>Click for Day 2 Certificate</button>
             </a>
             <div style={{marginTop:20}} className="alert alert-warning">If your Day 2 Workshop is mentioned wrong, <a href="https://docs.google.com/forms/d/e/1FAIpQLScY43vuGH895FbQdz6HrGmL6SidiSYO5RwhvrZGXtpaM5WLcQ/viewform" target="_blank">Please go to this Google Form and fill your details </a></div>  
            </div>

          </div>
        </div> </div></>);
      }
      else if(this.state.userProfile && this.state.userProfile.UID){
        return (  <>
          <div className="row" style={{padding:5,margin:5}}><div className="col-md-10 col-xs-12 " style={{padding: '0px', marginBottom: '100px', float: 'none',margin: '0 auto'}}>
          <div className="card" style={{margin: '8px', borderColor: '#282828'}}>
            <div className="card-header" style={{backgroundColor: '#282828', color: 'white', borderColor: '#282828'}}>
              <i className="fas fa-ticket-alt" />  Your Day 2 Selected Workshop
            </div>
            <div className="card-body " style={{margin: '0px'}}>
            <div><h4 style={{paddingBottom:10}}>You haven't selected your day2 workshop</h4></div>
          <hr/>
              <div className="row">
                <div className="col"><blockquote className="blockquote mb-0" style={{paddingBottom: '15px'}}>
                
                    <div>Venue</div>
                   
                    <div><p>The workshop seminar hall you will get to know on Day One of the Event. </p></div>
                  </blockquote>
                  <div>Time</div>
                  <p>September 29th 2019; 09:15AM - 04:00PM</p>
                </div>
             
              </div>
              <div style={{marginTop:20,fontSize:20,marginBottom:20}}><u>Your E-Certificate</u></div>
              <a href={"https://www.developerweekend.org/gencertificate/DAY1/"+this.state.userProfile.UID} target="_blank"><button type="button" class="btn btn-primary" style={{marginRight:20}}>Click for Day 1 Certificate</button></a>
           <div style={{marginTop:20}} className="alert alert-warning">Looks like you haven't selected your Day 2 Workshop. Please contact Support at info@developerweekend.org</div>
        
            </div>
            
          </div>
        </div> </div></>);
      }
      else{
        return (  <div style={{color:'white', textAlign:'center'}}> Please wait...</div>)
      }
       
    }
}
 
export default selectedWorkshopComponent;