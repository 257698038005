import React, { Component } from 'react';
import './vendor/bootstrap/css/bootstrap.min.css'
import './vendor/animate/animate.css'
import './vendor/css-hamburgers/hamburgers.min.css'
import './vendor/select2/select2.min.css'
import './vendor/css/util.css'
import './vendor/css/main.css'
import dwnewsmall from './vendor/images/dwnewsmall.png'
import SignOutButton from '../SignOut/index'

class headerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <div>
<div style={{borderRadius: '7px', backgroundColor: '#282828', margin: '9px'}} className="text-center"> 
       <SignOutButton/>
        <img src={dwnewsmall} style={{width: '200px'}} />
        <p style={{fontSize: '19px', color: 'white'}}>Bengaluru | September 28-29, 2019</p>
        <p style={{fontSize: '17px', color: 'white', paddingBottom: '16px'}}>Bangalore's Most Diverse Developer Conference</p>
      </div>

        </div> );
    }
}
 
export default headerComponent;