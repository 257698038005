import React, { Component } from 'react';
import './vendor/bootstrap/css/bootstrap.min.css'
import './vendor/animate/animate.css'
import './vendor/css-hamburgers/hamburgers.min.css'
import './vendor/select2/select2.min.css'
import './vendor/css/util.css'
import './vendor/css/main.css'

class ticketComponent extends Component {
  _initFirebase = false;
    constructor(props) {
        super(props);
        this.state = { 
          userProfile:null,
          loading: false,
          error: false,
         }
    }

    firebaseInit = () => {
        
      if (this.props.firebase && !this._initFirebase) {
          this._initFirebase = true;
          const fs = this.props.firebase;
          const thi = this;
          this.onListenForUserInfo(fs, thi);
      }
  };

  onListenForUserInfo = (fs, thi) => {
      this.setState({ loading: true });
     
      fs.user(thi.props.authUser.uid).get()
      .then(doc => {
        if (!doc.exists) {
          thi.setState({
            userProfile: null,
            error: true,
        });
        } else {
          this.setState({
            userProfile:doc.data(),
            loading: false
          })
        }
      })
      .catch(err => {
        thi.setState({
          userProfile: null,
          error: true,
      });
      });
    
  }

  componentDidMount() {
    this.firebaseInit();
}

componentDidUpdate() {
    this.firebaseInit();
}

    render() { 
      //console.log(this.props.cd.firebase.auth.currentUser)
      
          return ( 

            <>
            {this.state.loading && (<><p style={{textAlign:'center', color:'white', fontSize:20}}>Loading...</p></>)}

             {this.state.userProfile 
             && (<div className="row" style={{padding:5,margin:5}}><div className="col-md-10 col-xs-12 " style={{padding: '0px', marginBottom: '100px', float: 'none',margin: '0 auto'}}>
             <div className="card" style={{margin: '8px', borderColor: '#282828'}}>
               <div className="card-header" style={{backgroundColor: '#282828', color: 'white', borderColor: '#282828'}}>
                 <i className="fas fa-ticket-alt" />  Gate Pass & Ticket
               </div>
               <div className="card-body " style={{margin: '0px'}}>
               <div><h4 style={{paddingBottom:10}}>Name: {this.state.userProfile.Name}</h4>
               <h4 style={{paddingBottom:10}}>College: {this.state.userProfile.branch_job}</h4></div>
             <hr/>
                 <div className="row">
                   <div className="col"><blockquote className="blockquote mb-0" style={{paddingBottom: '15px'}}>
                       <div>Venue</div>
                      
                       <a target="_blank" href="https://goo.gl/maps/5GS7PSaFPKd2w6No8"> <div><p>REVA University, Rukmini Knowledge Park, Kattigenahalli, Yelahanka, Bengaluru, Karnataka 560064</p></div></a>
                     </blockquote>
                     <div>Time</div>
                     <p>September 28-29; 09:15AM - 04:30PM</p>
                   </div>
                   <div className="col" align="center"> <img className="rounded " src={"https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=UID000&"+this.state.userProfile.UID+"choe=UTF-8"} style={{width: '140px'}} />
                     <p>{this.state.userProfile.UID}</p></div>
                 </div>
               </div>
             </div>
           </div> </div>)

            } 
            </>);
      
    }
}
 
export default ticketComponent;