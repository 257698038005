import React, { Component } from 'react';
import './vendor/bootstrap/css/bootstrap.min.css'
import './vendor/animate/animate.css'
import './vendor/css-hamburgers/hamburgers.min.css'
import './vendor/select2/select2.min.css'
import './vendor/css/util.css'
import './vendor/css/main.css'

class selectDayTwoWorkshop extends Component {
  _initFirebase = false;
  constructor(props) {
      super(props);
      this.state = { 
        workshops:null,
        loading: false,
        error: false,
       }
  }

  firebaseInit = () => {
      
    if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;
        const fs = this.props.firebase;
        const thi = this;
        this.onListenForUserInfo(fs, thi);
    }
};

onListenForUserInfo = (fs, thi) => {
    this.setState({ loading: true });
   
    fs.workshops().get()
      .then(snapshot => {
    
    console.log(snapshot)
    //   snapshot.forEach(doc => {

    //    console.log(doc.id, '=>', doc.data());
    // });
        
    this.setState({ workshops: snapshot });

      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    
    // fs.user(thi.props.authUser.uid).get()
    // .then(doc => {
    //   if (!doc.exists) {
    //     thi.setState({
    //       userProfile: null,
    //       error: true,
    //   });
    //   } else {
    //     this.setState({
    //       userProfile:doc.data(),
    //       loading: false
    //     })
    //   }
    // })
    // .catch(err => {
    //   thi.setState({
    //     userProfile: null,
    //     error: true,
    // });
    // });
  
}

selectWorkshop= (wid,authUser,firebase,limit)=>{
console.log(wid)
console.log(this.props)
if(firebase){

  firebase.users().where("workshopName", "==", wid).get()
  .then(function(querySnapshot) {
    if(querySnapshot.size != limit){
  firebase.user(authUser.uid).update({
        "workshopName": wid,
    })
    .then(function() {
       window.location.reload()
    });
    }
    else{
      window.alert('Sorry. Registrations is Full for this workshop. You can select any other workshop')
    }
      
  })
  .catch(function(error) {
    window.alert('something went wrong. contact support'+error)
  });

}else{
window.alert('something went wrong. contact support')
}
}

componentDidMount() {
  this.firebaseInit();
}

componentDidUpdate() {
  this.firebaseInit();
}
   
render() {
  const items = []
  if(this.state.workshops){
  this.state.workshops.forEach(doc => {

   items.push(doc.data());
 });
}
  console.log(this.state.workshops)
  return (<>

      {
        items ? items.map(key => 

    {

      return    <div>
       <div className="col-sm-6 col-md-12" style={{marginBottom: '20px'}}>
         <div className="ticket-inner-container row-margin-bottom super-early-bird ticket-shadow" style={{boxShadow: '0 0 2px 0 rgba(0,0,0,0.07), 0 2px 2px 0 rgba(0,0,0,0.15)',padding:20, borderRadius: '2px', background: 'white', borderBottomLeftRadius: 0, marginBottom: 0, borderBottomRightRadius: 0}}>
             <div style={{paddingLeft: '15px'}}>
               <div className="row" style={{marginBottom: '20px'}}><img style={{height: '40px', width: '40px'}} className="pull-left img-responsive" src={key.workshopIcon} /></div>
               <div className="row">
                 <p style={{letterSpacing: 0, fontFamily: 'Roboto,arial,sans-serif', textAlign: 'left', fontSize: '18px', lineHeight: '24px', WebkitFontSmoothing: 'antialiased', color: '#424242'}}>{key.workshopName}</p>
               </div>
               <div className="row" style={{textAlign: 'left'}}>
                 <span style={{fontSize: '12px', lineHeight: '12px', display: 'inline-block', padding: '6px 8px', textTransform: 'capitalize', fontFamily: 'Roboto,arial,sans-serif', backgroundColor: 'rgb(255, 241, 118)'}}>{key.workshopType}</span>
               </div>
             </div>
         </div>
         <div className="ticket-inner-container row-margin-bottom super-early-bird ticket-shadow" style={{boxShadow: '0 0 2px 0 rgba(0,0,0,0.07), 0 2px 2px 0 rgba(0,0,0,0.15)', borderRadius: '2px', background: '#01091f', marginBottom: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: '8px'}}>
             <div className="row" style={{padding:20}}>
               <div className="col-md-12 col-xs-12" style={{paddingLeft:15}}>
                 <p style={{marginBottom: 0, color: 'white', letterSpacing: 0, fontFamily: 'Roboto,arial,sans-serif', textAlign: 'left', fontSize: '21px', WebkitFontSmoothing: 'antialiased', marginTop: '0px'}}>{key.workshopSpeakerName}</p>
                 <p style={{marginBottom: 0, color: 'white', letterSpacing: 0, fontFamily: 'Roboto,arial,sans-serif', textAlign: 'left', fontSize: '17px', WebkitFontSmoothing: 'antialiased'}}>{key.workshopSpeakerTitle}</p></div>
         </div>
           </div>
           <div className="ticket-inner-container row-margin-bottom super-early-bird ticket-shadow" style={{boxShadow: '0 0 2px 0 rgba(0,0,0,0.07), 0 2px 2px 0 rgba(0,0,0,0.15)', borderRadius: '2px', background: '#ffffff', marginBottom: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: '8px'}}>
             <div className="row">
               <div className="col-md-12 col-xs-9" style={{paddingLeft:15}}>
                 <p style={{marginBottom: 0, color: 'black', letterSpacing: 0, fontFamily: 'Roboto,arial,sans-serif', textAlign: 'left', fontSize: '15px', WebkitFontSmoothing: 'antialiased', marginTop: '0px'}}><a href="http://bit.ly/DWDAYTWO" target="_blank"><u>Click here for workshop prerequisites and software installation document</u></a></p>
                </div>
             </div>
        
           </div>
           <div className="ticket-inner-container row-margin-bottom super-early-bird ticket-shadow" style={{boxShadow: '0 0 2px 0 rgba(0,0,0,0.07), 0 2px 2px 0 rgba(0,0,0,0.15)', borderRadius: '2px', background: '#ffffff', marginBottom: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: '8px'}}>
             <div className="row">
               <div className="col-md-12 col-xs-9" style={{paddingLeft:15}}>
                 { key.valid ? <p onClick={() => this.selectWorkshop(key.workshopID,this.props.authUser,this.props.firebase,key.limit)} style={{"borderRadius":"6px",cursor:'pointer',"marginBottom":"0px","color":"white","letterSpacing":"0px","fontFamily":"Roboto, arial, sans-serif","fontSize":"15px","WebkitFontSmoothing":"antialiased","marginTop":"0px","padding":"10px","background":"#1e3c72","textAlign":"center"}}>{this.props.authUser.workshopName==key.workshopID ? <p style={{color:'green',background:'white',padding:10}}>SELECTED</p> : <p style={{color:'white'}}>Select Workshop</p>}</p> : <p style={{"borderRadius":"6px","marginBottom":"0px","color":"white","letterSpacing":"0px","fontFamily":"Roboto, arial, sans-serif","fontSize":"15px","WebkitFontSmoothing":"antialiased","marginTop":"0px","padding":"10px","background":"grey","textAlign":"center"}}>Registration at full capacity</p>}
                </div>
             </div>
           </div>
         </div>
               </div>
    
    }

      ) : <div style={{textAlign:'center', fontSize:20, color:'white'}}>Loading</div>
      }
  </>);
}

}
 
export default selectDayTwoWorkshop;